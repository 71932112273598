<template>
  <div>
    <b-container fluid class="p-5">
      <b-row class="justify-content-md-center">
        <b-col :cols="12" :md="4" :xl="3">
          <h1>{{ $t('Iniciar sesión') }}</h1>
          <p>
            {{ $t('¿Aún no tienes una cuenta en from:BEA? Regístrate') }}
            <b-link :to="localePath({ name: 'register' })">{{
              $t('aquí')
            }}</b-link>
          </p>

          <LoginSection /> </b-col
      ></b-row>
    </b-container>
  </div>
</template>

<script>
import LoginSection from '@/components/LoginSection.vue';

export default {
  name: 'login',
  components: { LoginSection },
};
</script>
