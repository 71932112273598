<template>
  <div>
    <b-row>
      <b-col :cols="12">
        <!-- <h3>Editar cliente</h3> -->
        <!-- Form -->
        <b-form @submit.stop.prevent="onSubmit">
          <b-alert :show="form.error.show" variant="danger">{{
            form.error.text
          }}</b-alert>

          <!-- <h4>Características</h4> -->

          <b-form-row>
            <b-form-group
              id="input-username"
              class="col-12"
              :label="$t('Email')"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="$v.form.username.$model"
                :state="validateState('username')"
                aria-describedby="input-username-feedback"
              ></b-form-input>
              <b-form-invalid-feedback
                v-if="!$v.form.username.username"
                id="input-username-feedback"
                >Debe ser un correo electrónico válido.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback v-else id="input-username-feedback"
                >Este campo es obligatorio.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group
              id="input-password"
              class="col-12"
              :label="$t('Contraseña')"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="$v.form.password.$model"
                :state="validateState('password')"
                type="password"
                aria-describedby="input-password-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-password-feedback"
                >Este campo es obligatorio.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-link
              :to="localePath({ name: 'forgot-password' })"
              v-b-modal.modal-terms-conditions
              class="pr-2 pl-2 primary-color"
              >{{ $t('¿Has olvidado la contraseña?') }}</b-link
            >
          </b-form-row>

          <b-button class="mt-4" type="submit" variant="primary">{{
            $t('Iniciar sesión')
          }}</b-button>
        </b-form>
        <!-- /Form -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

import { validationMixin } from 'vuelidate';
import { mapMutations } from 'vuex';

export default {
  name: 'LoginSection',
  mixins: [validationMixin],
  validations() {
    let rules = {
      username: {
        required,
        email,
      },
      password: {
        required,
      },
    };

    return {
      form: rules,
    };
  },
  data() {
    let form = {
      username: '',
      password: '',

      error: {
        show: false,
        text: '',
      },
    };

    return {
      form: form,
      countries: [],
    };
  },

  methods: {
    ...mapMutations({
      setOverlay: 'overlay/setOverlay',
      setAuth: 'auth/setAuth',
    }),
    capitalizeWords(string) {
      return string.replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.setOverlay(true);

      this.$actions
        .postAuth({
          username: this.$v.form.username.$model,
          password: this.$v.form.password.$model,
        })
        .then((response) => {
          this.setAuth({
            isAuthenticated: true,
            token: response.data.token,
            type: response.data.type,
            business_type: response.data.business_type,
          });

          this.setOverlay(false);

          this.$cookiez.set('token', response.data.token, {
            path: '/',
            maxAge: 60 * 60 * 24 * 7,
          });

          this.$router.replace(
            this.localePath({
              name:
                'redirect' in this.$route.query &&
                this.$route.query.redirect !== ''
                  ? this.$route.query.redirect
                  : 'index',
              params:
                this.$route.params && this.$route.params.params
                  ? this.$route.params.params
                  : {},
            })
          );
        })
        .catch((err) => {
          this.setOverlay(false);
          this.form.error.show = true;
          this.form.error.text = 'Datos incorrectos';
        });
    },
  },
};
</script>
